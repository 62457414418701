import React from 'react';

const PublicLayout = ({ children }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignContent: 'center',
        textAlign: 'center',
        padding: '0',
        backgroundColor: '#fff',
      }}
    >
      <div>{children}</div>
    </div>
  );
};
export default PublicLayout;
