import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setGeneralStatus } from '../redux/actions-v2/coreAction';

// Utilities
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';

// Components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '../stories/general-components/TextField';
import DatePickerUTC from '../stories/general-components/DatePickerUTC';
import { auth } from '../firebase';
import ModalLarge from './Base/ModalLarge';
import Button from '../stories/general-components/Button';
import moment from 'moment';

const ModalPayment = ({ isOpen, modalCloseHandler }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const singleCardDetails = useSelector(
    (state) => state.element.singleElementDetails
  );

  // State variables
  const [paymentType, setPaymentType] = useState('creditCard');
  const [paymentAmount, setPaymentAmount] = useState(
    singleCardDetails?.finances?.balance || 0
  );
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expDate, setExpDate] = useState('');
  const [date, setDate] = useState(moment());
  const [note, setNote] = useState('');
  const [txnId, setTxnId] = useState('');

  const handleDateChange = (event, fieldType, id) => {
    let value;

    switch (fieldType) {
      case 'date':
        value = id;
        break;
      case 'date-time':
        value = id;
        break;
      default:
        value = event.target.value;
    }
    setDate(value);
  };

  // Handle credit card payment
  const handleCreditCardPayment = async () => {
    modalCloseHandler();
    try {
      const formattedCCNumber = cardNumber.replace(/\s/g, '');
      const formattedExp = expDate.replace(/\s/g, '');
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'modal',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `payments-processPayment`,
        noAuth: true,
        body: {
          cardId: singleCardDetails.id,
          amount: paymentAmount,
          payorUser: singleCardDetails.targetId,
          businessId: singleCardDetails.ownerId,
          userName: auth?.currentUser?.displayName || '',
          cardNumber: formattedCCNumber,
          cvv: cvv,
          expDate: formattedExp,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'modal',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Handle cash payment
  const handleCashPayment = async () => {
    const formatDatesInObject = (obj) => {
      for (let key in obj) {
        if (moment.isMoment(obj[key])) {
          obj[key] = obj[key].utc().format();
        }
      }
      return obj;
    };
    modalCloseHandler();
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'modal',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `payments-processManualPayment`,
        noAuth: true,
        body: {
          cardId: singleCardDetails.id,
          amount: paymentAmount,
          payorUser: singleCardDetails.targetId,
          businessId: singleCardDetails.ownerId,
          userName: auth?.currentUser?.displayName || '',
          transactionType: 12,
          date: date ? formatDatesInObject(date) : new Date().toISOString(),
          note: note || '',
          txnId: txnId || '',
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'modal',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Handle terminal payment
  const handleTerminalPayment = async () => {
    const formatDatesInObject = (obj) => {
      for (let key in obj) {
        if (moment.isMoment(obj[key])) {
          obj[key] = obj[key].utc().format();
        }
      }
      return obj;
    };
    modalCloseHandler();
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'modal',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `payments-processManualPayment`,
        noAuth: true,
        body: {
          cardId: singleCardDetails.id,
          amount: paymentAmount,
          payorUser: singleCardDetails.targetId,
          businessId: singleCardDetails.ownerId,
          userName: auth?.currentUser?.displayName || '',
          transactionType: 11,
          date: date ? formatDatesInObject(date) : new Date().toISOString(),
          note: note || '',
          txnId: '',
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'modal',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Handle other payment types
  const handleOtherPayment = async () => {
    const formatDatesInObject = (obj) => {
      for (let key in obj) {
        if (moment.isMoment(obj[key])) {
          obj[key] = obj[key].utc().format();
        }
      }
      return obj;
    };
    modalCloseHandler();
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'modal',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `payments-processManualPayment`,
        noAuth: true,
        body: {
          cardId: singleCardDetails.id,
          amount: paymentAmount,
          payorUser: singleCardDetails.targetId,
          businessId: singleCardDetails.ownerId,
          userName: auth?.currentUser?.displayName || '',
          transactionType: 13,
          date: date ? formatDatesInObject(date) : new Date().toISOString(),
          note: note || '',
          txnId: txnId || '',
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'modal',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalLarge isOpen={isOpen} modalCloseHandler={modalCloseHandler}>
      <div className="h-40 p-2">
        <Tabs
          value={paymentType}
          onChange={(e, newValue) => setPaymentType(newValue)}
          centered
        >
          <Tab label={t('creditCard')} value="creditCard" />
          <Tab label={t('terminal')} value="terminal" />
          <Tab label={t('cash')} value="cash" />
          <Tab label={t('manual')} value="other" />
        </Tabs>

        {paymentType === 'creditCard' && (
          <div className="mt-2">
            <TextField
              label={t('amount')}
              fullWidth
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              type="number"
            />
            <TextField
              label={t('cardNumber')}
              fullWidth
              type="cc"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              sx={{ mt: 2 }}
              inputProps={{ maxLength: 16 }}
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <div className="d-flex mb-4">
              <div className="col-6">
                <TextField
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  label={t('expDate')}
                  mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                  type="cc"
                  value={expDate}
                  onChange={(e) => setExpDate(e.target.value)}
                />
              </div>
              <div style={{ paddingLeft: '20px' }} className="col-6">
                <TextField
                  fullWidth
                  label={t('cvv')}
                  mask={[/\d/, /\d/, /\d/]}
                  type="cc"
                  inputProps={{ maxLength: 3 }}
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                />{' '}
              </div>
            </div>
            <Button
              label={t('proceed')}
              fullWidth
              onClick={handleCreditCardPayment}
            />
          </div>
        )}

        {paymentType === 'cash' && (
          <div className="mt-2">
            <TextField
              label={t('amount')}
              fullWidth
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              type="number"
            />

            <TextField
              label={t('note')}
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={{ mt: 2 }}
            />
            <div className="d-flex mb-4">
              <div className="col-6">
                <DatePickerUTC
                  label={t('date')}
                  fullWidth
                  fieldType="date"
                  value={date}
                  onChange={handleDateChange}
                  sx={{ mt: 2 }}
                />
              </div>
              <div style={{ paddingLeft: '20px' }} className="col-6">
                <TextField
                  label={t('transactionID')}
                  fullWidth
                  value={txnId}
                  onChange={(e) => setTxnId(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>

            <Button
              label={t('proceed')}
              fullWidth
              onClick={handleCashPayment}
              sx={{ mt: 2 }}
            />
          </div>
        )}

        {paymentType === 'terminal' && (
          <div className="mt-2">
            <TextField
              label={t('amount')}
              fullWidth
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              type="number"
            />
            <div className="d-flex">
              <div className="col-6 mb-4">
                <DatePickerUTC
                  label={t('date')}
                  fullWidth
                  fieldType="date"
                  value={date}
                  onChange={handleDateChange}
                  sx={{ mt: 2 }}
                />
              </div>
              <div style={{ paddingLeft: '20px' }} className="col-6 mb-4">
                <TextField
                  label={t('note')}
                  fullWidth
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>
            <Button
              fullWidth
              label={t('proceed')}
              onClick={handleTerminalPayment}
              sx={{ mt: 2 }}
            />
          </div>
        )}

        {paymentType === 'other' && (
          <div className="mt-2">
            <TextField
              label={t('amount')}
              fullWidth
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              type="number"
            />

            <TextField
              label={t('note')}
              fullWidth
              value={note}
              onChange={(e) => setNote(e.target.value)}
              sx={{ mt: 2 }}
            />
            <div className="d-flex mb-4">
              <div className="col-6">
                <DatePickerUTC
                  label={t('date')}
                  fullWidth
                  fieldType="date"
                  value={date}
                  onChange={handleDateChange}
                  sx={{ mt: 2 }}
                />
              </div>

              <div style={{ paddingLeft: '20px' }} className="col-6">
                <TextField
                  label={t('transactionID')}
                  fullWidth
                  value={txnId}
                  onChange={(e) => setTxnId(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>
            <Button
              fullWidth
              label={t('proceed')}
              onClick={handleOtherPayment}
              sx={{ mt: 2 }}
            />
          </div>
        )}
      </div>
    </ModalLarge>
  );
};

export default ModalPayment;
