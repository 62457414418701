import nodeAxiosFirebase from './nodeAxiosFirebase';

const getSearchResults = async ({
  searchValue,
  t,
  currentLang,
  structureId,
}) => {
  const services = await nodeAxiosFirebase({
    t,
    method: 'POST',
    url: `coreMulti/list`,
    body: {
      query: searchValue,
      lang: currentLang,
      structureId: structureId,
    },
  });
  return services?.hits;
};

export default getSearchResults;
