import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, InputLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

export const SelectAutoMulti = ({
  label,
  fieldKey,
  selections,
  fieldType,
  field,
  value,
  size,
  onChange,
  error,
  required,
  parentData,
}) => {
  const { t, i18n } = useTranslation();
  const businessPreference = useSelector((state) => state.core.businessData);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDarkmode = theme.palette.mode === 'dark';

  const [selectionsOptions, setSelectionsOptions] = useState([]);
  const type = selections[0];
  const fieldFromType = type?.split(':')[0];
  const queryFromType = type?.split(':')[1];

  const lat = parentData?.lat?.toFixed(2);
  const lng = parentData?.lng?.toFixed(2);

  const getDocuments = async () => {
    try {
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreMulti/list`,
        body: {
          lang: i18n.language,
          structureId: field?.structureId || '3G34cQ3lKvGJfJIBCfvr',
          querySearch: '',
          aroundLat: Number(lat),
          aroundLng: Number(lng),
        },
      });
      setSelectionsOptions(
        response?.hits?.map((option) => {
          return {
            id: option?.id,
            label: option?.attribute1 || '',
            subLabel: option?.targetAddress || '',
            value: option?.targetId,
          };
        })
      );
    } catch (error) {
      console.error('Error fetching data');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  useEffect(() => {
    if (fieldFromType === 'geo' && queryFromType === 'radius') {
      getDocuments();
    }
  }, [parentData]);

  const onSelectionChange = (newValueId, targetId) => {
    onChange(newValueId, targetId);
  };

  return (
    <div
      style={{
        zIndex: 1000001,
      }}
      className="d-flex middle-content"
    >
      <div className={'col-12'}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink={true}
            required={required || false}
            error={error}
            sx={{
              backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '',
              padding: '2px 10px 2px 10px',
              borderRadius: '10px',
            }}
          >
            {label}
          </InputLabel>
          <Autocomplete
            options={selectionsOptions}
            disabled={!parentData}
            error={error}
            noOptionsText={t('noOption')}
            getOptionLabel={(option) => `${option?.label || '-'}`}
            value={value || ''}
            onChange={(event, newValue) => {
              if (newValue) {
                onSelectionChange(newValue.id, newValue.value);
              }
            }}
            renderOption={(props, option) => (
              <Box sx={{ height: '41px', textAlign: 'left' }} {...props}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography textAlign="left">{option?.label}</Typography>
                  <Typography
                    textAlign="left"
                    variant="caption"
                    color="textSecondary"
                    sx={{ textAlign: 'left', marginTop: '-5px' }}
                  >
                    {option?.subLabel || ''}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={error}
                disabled={!parentData}
                sx={{
                  'MuiFormControl-root': {
                    width: '100%',
                  },
                  '.MuiInputBase-input': {
                    height: '12px',
                    fontSize: size === 'small' ? '11px' : '13px',
                  },
                  '& .MuiFormLabel-root': {
                    backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
                    borderRadius: '10px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&.Mui-focused fieldset': {
                      borderColor: businessPreference?.mainColor || '#000',
                      boxShadow: `0 0 0 0.2rem ${
                        businessPreference?.mainColor + '20'
                      }`,
                    },
                  },
                }}
              />
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default SelectAutoMulti;
