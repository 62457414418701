import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Button, Dialog } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export const RangePickerButton = ({ value, onChange, type }) => {
  const [open, setOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf('day').format('YYYY-MM-DD'),
    moment().endOf('day').format('YYYY-MM-DD'),
  ]);
  const mode = localStorage.getItem('mode') || 'light';
  const { t } = useTranslation();

  useEffect(() => {
    if (value && value?.length === 2 && type !== 'single') {
      const formattedDates = value?.map((date) => (date ? moment(date) : null));
      setSelectedDates(formattedDates);
    } else if (value && value?.length === 1 && type === 'single') {
      setSelectedDates([moment(value[0]), moment(value[0])]);
    }
  }, [value]);

  useEffect(() => {
    if (open) {
      setSelectedDates([null, null]);
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const displayStartDates = () => {
    if (!selectedDates[0]?._isValid) return t('selectDate');
    return `${selectedDates[0].format('YYYY-MM-DD')} `;
  };

  const displayEndDates = () => {
    if (!selectedDates[1]?._isValid) return t('selectDate');
    return `${selectedDates[1].format('YYYY-MM-DD')} `;
  };

  const handleDateChange = (newValue) => {
    setSelectedDates(newValue);
    if (newValue[0] && newValue[1] && type !== 'single') {
      onChange(newValue);
      handleClose();
    } else if (newValue[0] && type === 'single') {
      onChange(newValue);
      handleClose();
    }
  };

  return (
    <div className="middle-content">
      <Dialog open={open} onClose={handleClose}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDateRangePicker
            value={selectedDates}
            format="YYYY-MM-DD"
            label={t('select')}
            startText={t('start')}
            endText={t('end')}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Dialog>

      {!open && (
        <Button
          startIcon={
            <EventOutlinedIcon color={mode === 'dark' ? 'white' : 'black'} />
          }
          sx={{
            marginTop: '-3px',
            width: '250px',
            color: mode === 'dark' ? 'white' : 'black',
          }}
          onClick={handleOpen}
        >
          <span style={{ color: mode === 'dark' ? 'white' : 'black' }}>
            {displayStartDates()}
            {type !== 'single' && ' - ' + displayEndDates()}
          </span>
        </Button>
      )}
    </div>
  );
};

export default RangePickerButton;
