import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import PublicLayout from '../../layouts/PublicLayout';
import {
  Button,
  Typography,
  Container,
  Grid,
  Box,
  Paper,
  Chip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { contentFr, seoContent } from './contentFr.js';
import { contentEn } from './contentEn.js';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import loadingAnimation from '../../lotties/ai.json';
import { Helmet } from 'react-helmet';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import InterestsIcon from '@mui/icons-material/Interests';
import { useTranslation } from 'react-i18next';
import BannerLeft from '../../components/website/BannerLeft';
import BothActions from '../../components/website/BothActions';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import CompareGrid from './CompareGrid';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import GeneralText from '../../stories/general-components/GeneralText.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';

const Industries = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contentName } = useParams();
  const lang = localStorage.getItem('i18nextLng');
  const [content, setContent] = useState({});
  const theme = useTheme();

  const pageData =
    lang === 'fr' ? contentFr[contentName] : contentEn[contentName];
  const seoData = seoContent[contentName];

  const navigateDemo = () => {
    navigate('/contact/demo');
  };

  const getLp = async () => {
    try {
      const lpContent = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `landPage`,
        noAuth: true,
        body: {
          docId: contentName,
        },
      });
      setContent(lpContent);
    } catch (error) {}
  };

  useEffect(() => {
    getLp();
  }, [contentName]);

  return (
    <PublicLayout>
      <Helmet>
        <title>{content?.name}</title>
        <meta name="description" content={content?.description || ''} />
        <meta name="keywords" content={content?.tags || []} />
      </Helmet>
      <Container sx={{ width: '90%', overflow: 'scroll', height: '100vh' }}>
        <div style={{ position: 'relative' }} className="d-flex">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{ marginTop: '195px' }}
                textAlign="center"
                marginTop="30%"
              >
                <Chip
                  label={content?.name}
                  color="primary"
                  variant="outlined"
                />
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{ marginTop: '65px' }}
                  color="black"
                  fontWeight={600}
                  fontSize={64}
                >
                  {content?.header}
                </Typography>
              </Box>
              <Box
                sx={{ marginTop: '20px', marginBottom: '40px' }}
                textAlign="center"
                marginTop="30%"
              >
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{ marginTop: '25px' }}
                  color="black"
                  fontWeight={500}
                  fontSize={24}
                >
                  {content?.subHeader}
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#1E1E1E',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                }}
                onClick={navigateDemo}
              >
                {content?.btnHeader}
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="mt-4 mb-5">
          <Grid container spacing={3} mt={12}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '240px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      overflow: 'hidden',
                    }}
                  >
                    <video
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={content?.imgBlock1}
                      autoPlay
                      loop
                      muted
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#1501F3',
                      height: '190px',
                      borderRadius: '12px',
                      padding: '30px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      color="#FFF"
                      fontWeight={500}
                      fontSize={12}
                    >
                      {content?.topBlock2}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="#FFF"
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={24}
                    >
                      {content?.middleBlock2}
                    </Typography>
                    <div className="d-flex justify-content-between mt-5">
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="#FFF"
                        fontWeight={400}
                        fontSize={14}
                      >
                        {content?.bottomBlock2}
                      </Typography>
                      <div>
                        <AutoAwesomeOutlinedIcon htmlColor="#FFF" />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '190px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      fontWeight={500}
                      sx={{ marginTop: '45px' }}
                      fontSize={12}
                    >
                      {content?.topBlock3}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={26}
                    >
                      {content?.middleBlock3}
                    </Typography>

                    <img
                      src={content?.imgBlock3}
                      width="150px"
                      style={{
                        width: '100%',
                        marginLeft: '58px',
                        marginTop: '-135px',
                      }}
                      className="float-animation-3"
                      alt="platform"
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#333333',
                      height: '100px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      color="#FFF"
                      fontWeight={600}
                      fontSize={32}
                    >
                      {content?.topBlock4}
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="#FFF"
                      sx={{ marginTop: '-12px' }}
                      fontWeight={400}
                      fontSize={12}
                    >
                      {content?.bottomBlock4}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '180px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      fontWeight={500}
                      fontSize={12}
                    >
                      {content?.topBlock5}
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ marginTop: '-7px' }}
                      fontWeight={600}
                      fontSize={24}
                    >
                      {content?.middleBlock5}
                    </Typography>
                    <div className="d-flex justify-content-between mt-5">
                      <Typography
                        variant="h5"
                        gutterBottom
                        fontWeight={400}
                        fontSize={14}
                      >
                        {content?.bottomBlock5}
                      </Typography>
                      <div>
                        <QrCodeScannerOutlinedIcon />
                      </div>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '310px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '105%',
                        marginBottom: '50px',
                        objectFit: 'cover',
                        marginLeft: '46px',
                      }}
                      className="float-animation-2"
                      src={content?.imgBlock6}
                      alt="platform node"
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#F5F5F5',
                      height: '120px',
                      borderRadius: '12px',
                      textAlign: 'left',
                      marginTop: '30px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={content?.imgBlock7}
                      alt="platform node"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: '#c5d609',
                      height: '120px',
                      borderRadius: '12px',
                      padding: '20px',
                      textAlign: 'left',
                      marginTop: '30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      color="#FFF"
                      fontWeight={500}
                      fontSize={12}
                    >
                      {content?.topBlock8}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="#FFF"
                      sx={{ marginTop: '-7px' }}
                      gutterBottom
                      fontWeight={600}
                      fontSize={24}
                    >
                      {content?.middleBlock8}
                    </Typography>
                    <div className="d-flex justify-content-between mt-1">
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="#FFF"
                        fontWeight={400}
                        fontSize={14}
                      >
                        {content?.bottomBlock8}
                      </Typography>
                      <div>
                        <MobileFriendlyOutlinedIcon htmlColor="#FFF" />
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{ marginTop: '80px', marginBottom: '70px' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da039_GoogleStartUp.png"
              alt="google"
              style={{ height: '27px', margin: '0 auto' }}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da045_PCINode.png"
              alt="PCI"
              style={{ height: '27px', margin: '0 auto' }}
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da035_makeNode.png"
              alt="make integromat"
              style={{ height: '29px', margin: '0 auto' }}
              className="hide-on-mobile"
            />
          </Paper>
          <Paper
            elevation={0}
            sx={{
              padding: '20px',
            }}
          >
            <img
              src="https://uploads-ssl.webflow.com/641cd341bb64d84ffad595f1/64eb9bc49f92b72fe49da03e_ProductHuntNode.png"
              alt="product hunt"
              style={{ height: '27px', margin: '0 auto' }}
              className="hide-on-mobile"
            />
          </Paper>
        </Box>{' '}
        <div className="mt-5 mb-5">
          <Paper
            elevation={0}
            sx={{
              backgroundColor: '#F5F5F5',
              borderRadius: '12px',
              padding: '40px',
              textAlign: 'center',
              marginTop: '50px',
              marginBottom: '150px',
            }}
          >
            {' '}
            <SupportAgentOutlinedIcon
              sx={{ marginTop: '15px' }}
              fontSize="large"
            />
            <div className="mb-5">
              <Typography
                variant="h5"
                gutterBottom
                sx={{ marginTop: '15px' }}
                fontWeight={600}
                fontSize={32}
              >
                {content?.titleSlide}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={400}
                fontSize={14}
              >
                {content?.descSlide}
              </Typography>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {content?.feature1Slide}
                </Typography>
              </div>
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {content?.feature2Slide}
                </Typography>
              </div>
              <div>
                <ThumbUpAltOutlinedIcon />
                <Typography
                  variant="h6"
                  gutterBottom
                  fontWeight={600}
                  fontSize={18}
                >
                  {content?.feature3Slide}
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
        <div className="mt-5 mb-5 px-5">
          <div className="row align-c">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: '155px', maxWidth: '720px' }}
              color="black"
              fontWeight={600}
              fontSize={32}
            >
              {content?.funcTitle}
            </Typography>
          </div>
          <div className="row align-c mb-3">
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                marginTop: '-5px',
              }}
              color="black"
              fontWeight={500}
              fontSize={16}
            >
              {content?.funcDescription}
            </Typography>{' '}
          </div>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={content?.funcImg1}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {content?.funcTitle1}
                  </Typography>
                  <GeneralText
                    text={content?.funcDesc1}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={content?.funcImg2}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {content?.funcTitle2}
                  </Typography>
                  <GeneralText
                    text={content?.funcDesc2}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginBottom: '200px' }}>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={content?.funcImg3}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {content?.funcTitle3}
                  </Typography>
                  <GeneralText
                    text={content?.funcDesc3}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="row mt-5">
                <div className="col-12 align-left">
                  <img
                    src={content?.funcImg4}
                    alt="Node"
                    style={{ height: '130px', maxWidth: '90%' }}
                  />

                  <Typography
                    variant="h4"
                    gutterBottom
                    color="black"
                    fontWeight={600}
                    fontSize={20}
                  >
                    {content?.funcTitle4}
                  </Typography>
                  <GeneralText
                    text={content?.funcDesc4}
                    fontSize="14px"
                    size="regular"
                    primary={true}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: '#F5F5F5',
            borderRadius: '12px',
            textAlign: 'center',
            marginTop: '200px',
            marginBottom: '200px',
            paddingTop: '40px',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          <div className="row">
            <div className="col-md-6 col-12">
              <img
                src={content?.secSlideImg}
                alt="real service no limits"
                style={{ height: 'auto', width: '100%' }}
              />
            </div>
            <div className="col-md-6 col-12 align-c">
              <Typography
                variant="h5"
                gutterBottom
                sx={{ marginTop: '15px' }}
                fontWeight={600}
                fontSize={32}
              >
                {content?.secSlideTitle}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={400}
                fontSize={14}
              >
                {content?.secSlideDescription}
              </Typography>
            </div>
          </div>
        </Paper>
        <div>
          <div className="mt-5 px-5">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginTop: '155px' }}
              fontWeight={600}
              fontSize={32}
            >
              {content?.mainFeatureTitle}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                paddingLeft: '10%',
                paddingRight: '10%',
                marginTop: '-5px',
              }}
              fontWeight={500}
              fontSize={24}
            >
              {content?.mainFeatureSub}
            </Typography>
          </div>
          <img
            style={{ marginTop: '-10px' }}
            src={content?.mainFeatureImg}
            width="700px"
            alt={contentName}
          />
        </div>
        <BannerLeft
          title={t('handsFreeMobile')}
          subText={t('handsFreeMobileText')}
          mainImg="/assets/website/img/sport-node.png"
          alignement="right"
          tile1={'Search-bar-ID'}
          faqType="mobile"
          mainImgSub="/assets/website/img/manufacturer-node.png"
        />
        <BothActions
          title1={t('discussAboutBusiness')}
          title2={t('discoverModules')}
          subText1={t('discussAboutBusinessText')}
          subText2={t('discoverModulesText')}
          titleComponent={t('alwaysHereToHelp')}
          icon1={
            <MarkChatReadOutlinedIcon htmlColor="#FFFFFF" fontSize="large" />
          }
        />
      </Container>
    </PublicLayout>
  );
};

export default Industries;
