import React, { useState, useEffect } from 'react';

//utilities
import { useTranslation } from 'react-i18next';

//components
import { ListItem } from '@material-ui/core';
import { StarOutline } from '@mui/icons-material';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import {
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

const GooglePageBusiness = ({ elementData }) => {
  const { t } = useTranslation();
  const [tempPlaces, setTempPlaces] = useState([]);
  const [placeDetails, setPlaceDetails] = useState({});

  const updateDoc = async (idGoogle) => {
    const formattedPath = elementData?.documentPath.split('/');
    const pathFinal = formattedPath
      .filter((part) => part !== elementData?.id)
      .join('/');

    try {
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSingle`,
        body: {
          documentId: elementData?.id,
          elementPath: pathFinal,
          key: 'googlePageBusinessIdentifiant',
          value: idGoogle,
        },
      });
      setTempPlaces([]);
      const dataFieldSec = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `businessDetails`,
        body: {
          placeId: idGoogle,
          fields: [],
        },
      });
      setPlaceDetails(dataFieldSec?.data);
    } catch (error) {
      console.error('Error fetching data');
    }
  };

  const resetID = async () => {
    const formattedPath = elementData?.documentPath.split('/');
    const pathFinal = formattedPath
      .filter((part) => part !== elementData?.id)
      .join('/');
    try {
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `coreSingle`,
        body: {
          documentId: elementData?.id,
          elementPath: pathFinal,
          key: 'googlePageBusinessIdentifiant',
          value: '',
        },
      });
      setTempPlaces([]);
      setPlaceDetails({});
      const placesSec = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `places`,
        body: {},
      });

      if (placesSec?.data?.length === 1) {
        const idGoogleSec = placesSec?.data?.[0]?.place_id;
        updateDoc(idGoogleSec);
      } else {
        setTempPlaces(placesSec?.data);
      }
    } catch (error) {
      console.error('Error fetching data');
    }
  };

  const fetchPlaces = async () => {
    try {
      if (elementData?.googlePageBusinessIdentifiant) {
        const dataField = await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `businessDetails`,
          body: {
            placeId: elementData?.googlePageBusinessIdentifiant,
            fields: [],
          },
        });
        setPlaceDetails(dataField?.data);
      } else {
        const places = await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `places`,
          body: {},
        });
        if (places?.data?.length === 1) {
          const idGoogle = places?.data?.[0]?.place_id;
          updateDoc(idGoogle);
        } else {
          setTempPlaces(places?.data);
        }
      }
    } catch (error) {
      console.error('Error fetching data');
    }
  };

  useEffect(() => {
    fetchPlaces();
  });

  return (
    <div>
      {placeDetails?.name && (
        <div className="mt-3 mx-3 d-flex">
          <img
            src={placeDetails?.icon}
            alt="icon"
            style={{ width: '30px', height: '30px', marginRight: '10px' }}
          />
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '20px' }}>
            {placeDetails?.user_ratings_total} {t('reviews')}
          </Typography>
          <Divider
            orientation="vertical"
            component="div"
            sx={{ margin: '8px' }}
          />
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '20px' }}>
            {placeDetails?.rating} {t('avg')}
          </Typography>
        </div>
      )}
      <div className="mx-3">
        <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '11px' }}>
          {placeDetails?.formatted_address || ''}
        </Typography>
        <Typography
          onClick={resetID}
          variant="h6"
          sx={{ fontWeight: 300, fontSize: '9px', cursor: 'pointer' }}
        >
          {t('reset')}
        </Typography>
      </div>
      {tempPlaces?.length > 0 && (
        <List dense>
          {tempPlaces?.map((place) => {
            return (
              <ListItem
                key={place?.id}
                dense
                divider
                button
                onClick={() => updateDoc(place?.place_id)}
              >
                <ListItemText
                  primary={place?.name}
                  secondary={place?.formatted_address || ''}
                  primaryTypographyProps={{
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                  secondaryTypographyProps={{
                    fontSize: '11px',
                    fontWeight: 400,
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      )}
      <List dense>
        {placeDetails?.reviews?.map((review) => {
          return (
            <ListItem key={review?.author_name} dense divider>
              <ListItemText
                primary={
                  review?.author_name +
                  ' - ' +
                  review?.relative_time_description
                }
                secondary={review?.text}
                primaryTypographyProps={{
                  fontWeight: 600,
                  fontSize: '12px',
                }}
                secondaryTypographyProps={{
                  fontSize: '11px',
                  fontWeight: 400,
                }}
              />
              <ListItemIcon>
                <StarOutline />
                <Typography
                  variant="caption"
                  fontSize="14px"
                  sx={{ marginLeft: '5px' }}
                  color="textSecondary"
                  display="block"
                  fontWeight={600}
                >
                  {review?.rating}
                </Typography>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
export default GooglePageBusiness;
