import React, { useEffect, useState, useRef } from 'react';
import PublicLayout from '../../layouts/PublicLayout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

//components
import CardInformationTag from '../../components/@generalComponents/CardInformationTag';
import Button from '../../stories/general-components/Button';
import GeneralText from '../../stories/general-components/GeneralText';
import BusinessInformationTag from '../../components/@generalComponents/BusinessInformationTag';
import { Divider } from '@mui/material';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';

const libraries = ['places'];

const PublicProject = () => {
  const componentRef = useRef(null);
  const { domain, cardId } = useParams();
  const { search } = useLocation();

  const accessToken = new URLSearchParams(search).get('accessToken');
  const accessCode = new URLSearchParams(search).get('accessCode');

  const [cardData, setCardData] = useState([]);
  const [approved, setApproved] = useState(false);
  const [isCardLoaded, setIsCardLoaded] = useState(false);
  const [isGeo, setIsGeo] = useState(false);

  const [error, setError] = useState(false);

  const { t, i18n } = useTranslation();
  const language = i18n.language || 'fr';
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });

  const navigateHome = () => {
    window.location.href = '/';
  };

  const getCardDetails = async () => {
    try {
      const card = await nodeAxiosFirebase({
        method: 'POST',
        url: `cards-getSingle`,
        noAuth: true,
        body: {
          cardId: cardId,
          accessCode: accessCode || accessToken,
          lang: language,
        },
      });
      setCardData(card);
      setIsCardLoaded(true);
    } catch (error) {
      setError(true);
    }
  };

  const [firstItemDetails, setFirstItemDetails] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    getCardDetails();
  }, [cardId, domain]);

  useEffect(() => {
    if (isCardLoaded && cardData?.items && typeof cardData.items === 'object') {
      const firstItemEntry = Object.entries(cardData?.items)[0];
      if (firstItemEntry) {
        const [itemId, itemDetails] = firstItemEntry;
        setFirstItemDetails({ itemId, ...itemDetails });
      }
      setIsGeo(true);
    }
  }, [isCardLoaded, cardId, isLoaded]);

  useEffect(() => {
    if (isGeo && cardData?.items && cardData?.targetMiddleDetails?.address) {
      const address =
        cardData?.targetMiddleDetails?.address +
        ', ' +
        cardData?.targetMiddleDetails?.city;

      if (address && isLoaded) {
        getCoordinates(address).then((coordinates) => {
          if (coordinates) setDefaultCenter(coordinates);
        });
      }
    }
  }, [isGeo, isLoaded]);

  // Function to handle print
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function updateLanguageToEnglish() {
    i18n.changeLanguage('en');
  }

  function updateLanguageToFrench() {
    i18n.changeLanguage('fr');
  }

  const getCoordinates = async (address) => {
    if (isLoaded && cardData?.targetMiddleDetails?.address) {
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        return { lat, lng };
      } catch (error) {
        console.error('Error getting coordinates: ', error);
        return null;
      }
    }
  };

  const updateStatus = async () => {
    if (cardData) {
      try {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `cards-confirm`,
          noAuth: true,
          body: {
            cardId: cardId,
            accessCode: accessCode || accessToken,
            status: parseInt(cardData?.businessData?.approvalStatus),
          },
        });
        setApproved(true);
      } catch (error) {
        console.error('Error updating data:', error);
      }
    }
  };

  useEffect(() => {
    if (cardData?.status === 0) {
      const updateOnView = async () => {
        try {
          await nodeAxiosFirebase({
            t,
            method: 'POST',
            url: `cards-confirm`,
            noAuth: true,
            body: {
              cardId: cardId,
              accessCode: accessCode || accessToken,
              status: parseInt(1),
            },
          });
          setApproved(true);
        } catch (error) {
          console.error('Error updating data:', error);
        }
      };
      updateOnView();
    }
  }, [cardId, cardData?.status]);

  const svgMarker = {
    path: 'M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
    fillColor: '#000',
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
  };

  return (
    <PublicLayout>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
        className="row align-c mt-3"
      >
        {' '}
        {cardData?.id === cardId && (
          <div>
            <div className="mt-4 align-center d-flex mb-3">
              <div className="col-md-3 col-6 mx-5 align-right">
                <Button
                  label={t('print')}
                  primary={false}
                  size="sm"
                  variant="text"
                  endIcon="PrintOutlined"
                  onClick={handlePrint}
                />
              </div>

              <div className="col-md-3 col-6 mx-2 align-right">
                {language === 'fr' ? (
                  <Button
                    label={t('english')}
                    primary={true}
                    variant="text"
                    size="sm"
                    onClick={updateLanguageToEnglish}
                  />
                ) : (
                  <Button
                    label={t('french')}
                    primary={true}
                    variant="text"
                    size="sm"
                    onClick={updateLanguageToFrench}
                  />
                )}
              </div>
            </div>
            <Divider component="div" />
            <div
              className="row"
              style={{
                overflowY: 'auto',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              id="report-pdf"
              ref={componentRef}
            >
              <div className="row mb-3 mt-3 px-5">
                <div className="col-md-6 col-12 mt-2 px-3">
                  <div className="mt-4">
                    <BusinessInformationTag
                      businessData={cardData?.businessData}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 mt-2 px-3">
                  <CardInformationTag
                    card={cardData}
                    langCode={language || 'fr'}
                  />
                </div>
              </div>
              <div className="mt-1 mb-2 px-5 align-c">
                <div className="row px-3">
                  <div className="col-md-6 align-left col-12 mt-2 mb-2">
                    <div>
                      <GeneralText
                        size="bold"
                        primary={true}
                        fontSize="12px"
                        text={
                          cardData?.targetMiddleDetails?.name ||
                          cardData?.targetDetails?.name
                        }
                      />
                    </div>
                    <div>
                      <GeneralText
                        size="medium"
                        primary={true}
                        fontSize="12px"
                        text={
                          (cardData?.targetMiddleDetails?.address || '') +
                          ' ' +
                          (cardData?.targetMiddleDetails?.city || '')
                        }
                      />
                    </div>

                    <div className="mt-3">
                      <div className="d-flex">
                        <GeneralText
                          size="regular"
                          primary={true}
                          fontSize="11px"
                          text={t('phone') + ':'}
                        />
                        <GeneralText
                          size="regular"
                          primary={true}
                          fontSize="11px"
                          text={
                            cardData?.targetMiddleDetails?.phone ||
                            cardData?.targetDetails?.phone ||
                            '-'
                          }
                        />
                      </div>
                      <div className="d-flex">
                        <GeneralText
                          size="regular"
                          primary={true}
                          fontSize="11px"
                          text={t('email') + ':'}
                        />
                        <GeneralText
                          size="regular"
                          primary={true}
                          fontSize="11px"
                          text={
                            cardData?.targetMiddleDetails?.email ||
                            cardData?.targetDetails?.email ||
                            '-'
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12 mt-2 mb-2"></div>
                </div>

                <div className="mt-3 mb-2 ">
                  <Button
                    label={cardData?.businessData?.approvalTerm}
                    primary={true}
                    size="medium"
                    buttonSx={{ backgroundColor: '#97c215' }}
                    disabled={
                      approved ||
                      cardData?.status ===
                        parseInt(cardData?.businessData?.approvalStatus)
                    }
                    onClick={updateStatus}
                  />
                </div>
              </div>

              <div className="row mt-4 px-5 mb-5">
                {firstItemDetails && (
                  <div
                    className="col-12 align-left mt-4"
                    key={firstItemDetails?.itemId}
                  >
                    <div>
                      <GeneralText
                        fontSize="14px"
                        text={firstItemDetails?.name}
                        primary={true}
                        size="bold"
                      />
                    </div>
                    <div className="mt-2">
                      <GeneralText
                        fontSize="11px"
                        text={cardData?.data?.type}
                        primary={true}
                        size="medium"
                      />
                    </div>
                    <div className="mt-2">
                      <GeneralText
                        fontSize="11px"
                        markdown
                        text={cardData?.data?.request}
                        primary={true}
                        size="regular"
                      />
                    </div>
                    <div className="mt-2">
                      <GeneralText
                        fontSize="11px"
                        markdown
                        text={cardData?.data?.model}
                        primary={true}
                        size="regular"
                      />
                    </div>
                  </div>
                )}

                {cardData?.targetMiddleDetails?.address && isGeo && (
                  <div className="hei-4 col-12 px-1 mt-4">
                    {' '}
                    {isLoaded &&
                      defaultCenter.lat !== 0 &&
                      defaultCenter.lng !== 0 && (
                        <div className="hei-4 col-12 px-3 mt-4">
                          <GoogleMap
                            options={{
                              mapTypeControl: false,
                              styles: [{ stylers: [{ saturation: -100 }] }],
                            }}
                            mapContainerStyle={{
                              width: '100%',
                              height: '30vh',
                              borderRadius: '6px',
                              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.10)',
                            }}
                            center={defaultCenter}
                            zoom={10}
                          >
                            <Marker position={defaultCenter} icon={svgMarker} />
                          </GoogleMap>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>{' '}
            <Divider component="div" className="px-3" />
            <div className="mb-2 px-4 mt-5 align-left" onClick={navigateHome}>
              <div className="d-flex mb-2">
                <img
                  src="/assets/v3/img/icon-node-grey.png"
                  width={67}
                  height={16}
                  className="hover mt-2"
                  alt="Node"
                  style={{
                    opacity: 0.6,
                  }}
                />
                <div>
                  <GeneralText
                    primary={true}
                    fontSize="8px"
                    classNameComponent="grey-text hover px-3"
                    text={'Intelligence Node Canada™'}
                  />
                  <div
                    style={{
                      marginTop: '-6px',
                    }}
                  >
                    <GeneralText
                      primary={true}
                      fontSize="8px"
                      classNameComponent="grey-text hover px-3"
                      text={
                        t('allRightsReserved') +
                        ' - ' +
                        new Date().getFullYear()
                      }
                    />{' '}
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <GeneralText
                  primary={true}
                  fontSize="8px"
                  classNameComponent="grey-text"
                  text={t('bottom_invoice_text')}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PublicLayout>
  );
};

export default PublicProject;
