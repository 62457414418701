import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//utilities
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, addDoc, serverTimestamp, doc } from 'firebase/firestore';

//components
import Button from '../../general-components/Button';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import { Dialog, DialogActions } from '@mui/material';
import CustomDatePicker from '../../general-components/DatePickerUTC';
import TextField from '../../general-components/TextField';
import Select from '../../general-components/Select';
import { setGeneralStatus } from '../../../redux/actions-v2/coreAction';

const ActionBtn = ({ _ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const elementData = useSelector(
    (state) => state.element.singleElementDetails
  );

  const [open, setOpen] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [structureIden, setStructureIden] = useState('');
  const [value, setValue] = useState('');
  const currentUser = useSelector((state) => state.core.user);
  const businessFirebaseID = localStorage.getItem('businessId');

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const structures = businessStructure?.structures;

  const contactStructure = structures?.find(
    (structure) => structure.collectionField === 'contacts'
  );

  const handleAction = async () => {
    try {
      const formatedData = {
        name: elementData?.name || elementData?.targetName || '',
        tags: [],
        elementPath: '/users',
        structureId: contactStructure?.id,
        data: {
          targetEmail: elementData?.targetEmail || '',
          targetPhone: elementData?.targetPhone || '',
          targetLang: '',
          targetName: elementData?.name || elementData?.targetName || '',
          targetPrefix: elementData?.targetPrefix || 'NM',
          targetReference: elementData?.targetReference || '',
          targetAddress: elementData?.targetAddress || '',
        },
      };

      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `coreSingle`,
        body: formatedData,
      });
      const newElementId = response.elementId;

      navigate(
        `/app/element/contacts/${contactStructure?.id}/${
          newElementId + businessFirebaseID
        }`
      );
    } catch (error) {
      console.error('Error fetching data');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleChangeStructure = (value) => {
    setStructureIden(value);
  };

  const handleActionSec = async () => {
    setOpen(true);
  };
  const formattedPhone = elementData?.targetPhone.replace(/\s/g, '');

  const handleActionThird = async () => {
    const businessRef = doc(db, 'businessesOnNode', businessFirebaseID);
    window.location.href = `tel:${elementData?.targetPhone}`;
    await addDoc(collection(db, 'grids', elementData?.id, 'logs'), {
      elementId: elementData?.id,
      description: t('callInitiated') + ': ' + elementData?.targetPhone,
      timeStamp: serverTimestamp(),
      reference: formattedPhone,
      name: currentUser?.displayName || '-',
      type: 'action:call',
      ownerId: businessRef,
      targetId: null,
      assignedToId: currentUser?.uid || null,
      isDone: false,
    });
  };

  const handleCloseDialog = async () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseDialog}>
        <div style={{ width: '500px' }} className="p-4">
          <div>
            <Select
              value={structureIden}
              size="small"
              noEmpty
              fullWidth
              onChange={(e, value) => handleChangeStructure(value)}
              label={t('structure')}
              selections={structures?.map((structure) => {
                return {
                  label: structure?.name,
                  id: structure?.id,
                  value: structure?.id,
                };
              })}
            />
          </div>
          <div>
            <TextField
              key={'name' + elementData?.id}
              required={true}
              fullWidth
              name={t('name')}
              fieldType={'text'}
              label={t('name')}
              onChange={(e, fieldType) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </div>
          <CustomDatePicker
            key={'targetDate' + elementData?.id}
            required={true}
            fullWidth
            name={t('targetDate')}
            type={'date'}
            label={t('targetDate')}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e, fieldType, date) => {
              setTargetDate(date);
            }}
            value={targetDate}
          />
          <div>
            <TextField
              key={'description' + elementData?.id}
              required={true}
              fullWidth
              name={t('description')}
              fieldType={'text'}
              label={t('notes')}
              onChange={(e, fieldType) => {
                setDescription(e.target.value);
              }}
              value={description}
            />
          </div>
          <div>
            <TextField
              key={'value' + elementData?.id}
              fullWidth
              name={t('value')}
              fieldType={'number'}
              type={'number'}
              label={t('value')}
              onChange={(e, fieldType) => {
                setValue(e.target.value);
              }}
              value={value}
            />
          </div>
        </div>
        <DialogActions>
          <Button
            label={t('convert')}
            onClick={handleAction}
            buttonSx={{
              backgroundColor: '#000',
            }}
            classNameComponent="capitalize hover"
          />
        </DialogActions>
      </Dialog>
      <div>
        <Button
          fullWidth
          label={t('convert')}
          onClick={handleActionSec}
          endIcon="SwitchAccessShortcutOutlined"
          buttonSx={{
            backgroundColor: '#000',
          }}
          classNameComponent="capitalize hover"
        />
      </div>
      <div className="mt-3 mb-3">
        <Button
          fullWidth
          label={t('makeCall')}
          onClick={handleActionThird}
          endIcon="Call"
          buttonSx={{
            backgroundColor: businessPreference?.mainColor || '#000',
          }}
          classNameComponent="capitalize hover"
        />
      </div>
    </div>
  );
};
export default ActionBtn;
