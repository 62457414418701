import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';
import TextField from '../../stories/general-components/TextField';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import {
  ListItem,
  IconButton,
  ListItemText,
  FormControl,
  InputLabel,
  ButtonBase,
  Typography,
} from '@mui/material';
import Button from '../../stories/general-components/Button';
import { DragHandle, Delete, CastConnected } from '@mui/icons-material';
import { sortableHandle } from 'react-sortable-hoc';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import IconUploader from '../../components/@generalComponents/IconUploader';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.bubble.css';
import getRandomString from '../../utils/getRandomString';
import { toast } from 'react-toastify';

const Marketing = () => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [flowName, setFlowName] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [slides, setSlides] = useState([]);
  const [description, setDescription] = useState('');
  const [flows, setFlows] = useState([]);
  const [flowId, setFlowId] = useState('');
  const [newFlow, setNewFlow] = useState(false);
  const [slideTitle, setSlideTitle] = useState('');
  const [slideContent, setSlideContent] = useState('');
  const [slideMedia, setSlideMedia] = useState('');
  const [slideTab, setSlideTab] = useState('');
  const [slideSec, setSlideSec] = useState('');
  const [slideBody, setSlideBody] = useState('');
  const [slideTemplate, setSlideTemplate] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [slideId, setSlideId] = useState('');
  const [editingSlideIndex, setEditingSlideIndex] = useState(null);

  const setFlow = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `automations-setFlowMaster`,
        body: {
          flowId: flowId,
          name: flowName,
          description: description,
          isActive: isPublic,
          slides: slides,
        },
      });
    } catch (error) {
      console.error('Error set flow master:', error);
    }
  };

  const getFlows = async () => {
    try {
      const response = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `automations-getFlowsMaster`,
      });
      setFlows(response?.flows);
    } catch (error) {
      console.error('Error get flows master:', error);
    }
  };

  useEffect(() => {
    getFlows();
  }, []);

  const handleCreate = () => {
    setNewFlow(true);
    setFlowName('');
    setDescription('');
  };

  const handleUpdate = () => {
    setFlow();
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddSlide = () => {
    setSlides([
      ...slides,
      {
        title: slideTitle,
        content: slideContent,
        media: slideMedia,
        tab: slideTab,
        sec: slideSec,
        body: slideBody,
        template: slideTemplate,
        id: getRandomString(6),
      },
    ]);
    setSlideTitle('');
    setSlideContent('');
    setSlideTab('');
    setSlideMedia('');
    setSlideSec('');
    setSlideBody('');
    setSlideTemplate('');
  };

  const handleSelectFlow = (flow) => {
    setFlowId(flow?.id);
    setFlowName(flow?.name);
    setDescription(flow?.description);
    setSlides(flow?.slides);
  };

  const DragHandleIcon = sortableHandle(() => <DragHandle />);

  const SlideComponent = ({ slide, slideIndex }) => (
    <ListItem
      dense
      divider
      onClick={() => handleEditSlide(slideIndex)}
      sx={{
        backgroundColor: editingSlideIndex === slideIndex ? '#f2f2f2' : '',
      }}
    >
      <IconButton disableFocusRipple disableRipple>
        <DragHandleIcon />
      </IconButton>
      <ListItemText
        primaryTypographyProps={{ fontWeight: 600 }}
        primary={slide?.tab}
        secondary={slide?.title}
      />

      <IconButton onClick={() => handleDeleteSlide(slideIndex)}>
        <Delete />
      </IconButton>
    </ListItem>
  );

  const handleUploadComplete = (files) => {
    const fileUrls = files?.map((file) => file.fileUrl);
    setSlideMedia(fileUrls[0]);
  };

  const handleUploadBlur = (files) => {
    const fileUrls = files?.map((file) => file.fileUrl);
    setSlideMedia(fileUrls[0]);
  };

  const handleUploadCompleteSec = (files) => {
    const fileUrls = files?.map((file) => file.fileUrl);
    setSlideSec(fileUrls[0]);
  };

  const handleUploadBlurSec = (files) => {
    const fileUrls = files?.map((file) => file.fileUrl);
    setSlideSec(fileUrls[0]);
  };

  const handleEditSlide = (index) => {
    if (slides[index]) {
      setEditingSlideIndex(index);
    } else {
      console.error(`Slide at index ${index} not found`);
    }
  };

  useEffect(() => {
    if (slides[editingSlideIndex]) {
      setSlideId(slides[editingSlideIndex]?.id);
      setSlideTitle(slides[editingSlideIndex]?.title);
      setSlideContent(slides[editingSlideIndex]?.content);
      setSlideMedia(slides[editingSlideIndex]?.media);
      setSlideTab(slides[editingSlideIndex]?.tab);
      setSlideSec(slides[editingSlideIndex]?.sec);
      setSlideBody(slides[editingSlideIndex]?.body);
      setSlideTemplate(slides[editingSlideIndex]?.template);
    } else {
      console.error(`Slide at index ${editingSlideIndex} not found`);
    }
  }, [editingSlideIndex]);

  const handleUpdateSlide = () => {
    const newSlides = [...slides];
    newSlides[editingSlideIndex] = {
      title: slideTitle,
      tab: slideTab,
      content: slideContent,
      media: slideMedia,
      sec: slideSec,
      body: slideBody,
      template: slideTemplate,
      id: getRandomString(6),
    };
    setSlides(newSlides);
    setSlideTitle('');
    setSlideTab('');
    setSlideContent('');
    setSlideMedia('');
    setSlideSec('');
    setSlideBody('');
    setSlideTemplate('');
    setEditingSlideIndex(null);
  };

  const handleDeleteSlide = (index) => {
    const newSlides = [...slides];
    newSlides.splice(index, 1);
    setSlides(newSlides);
  };

  const handleSlideBody = (value) => {
    setSlideBody(value);
  };

  const handleTemplateChange = (template) => {
    setSlideTemplate(template);
    if (template === 'C' || template === 'D') {
      setSlideSec('');
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(slides);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSlides(items);
  };

  const handleShare = () => {
    if (flowId) {
      const link = 'https://usenode.com/presentation?flowId=' + flowId;
      navigator.clipboard.writeText(link);
      toast.success(t('copied'));
    }
  };

  return (
    <MainLayoutV2
      pageTitle={flowId ? flowName : t('/presentations')}
      add={!flowId && handleCreate}
      save={(flowId || newFlow) && handleUpdate}
      share={flowId && handleShare}
    >
      <Block height={1} heightPercentage={100}>
        {!flowId && (
          <div className="d-flex">
            {flows?.map((flow, index) => (
              <div
                key={flow?.id}
                style={{
                  border: '1px solid lightgrey',
                  borderRadius: '10px',
                  height: '140px',
                  cursor: 'pointer',
                }}
                className="mx-3 mt-3 mb-3 p-3 col-2 align-c selection-block"
              >
                <div className="py-2 align-c">
                  <Typography
                    variant="h6"
                    fontSize="14px"
                    fontWeight={600}
                    onClick={() => handleSelectFlow(flow)}
                  >
                    {flow?.name}
                  </Typography>
                  <div className="mt-2">
                    <Typography
                      variant="p"
                      fontSize="11px"
                      fontWeight={400}
                      onClick={() => handleSelectFlow(flow)}
                    >
                      {flow?.description}
                    </Typography>
                  </div>
                  <div className="align-c d-flex middle-content mt-3">
                    <CastConnected />
                    <Typography
                      variant="p"
                      fontSize="12px"
                      sx={{ padding: '0 5px' }}
                      fontWeight={600}
                      onClick={() => handleSelectFlow(flow)}
                    >
                      {flow?.slides?.length}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {(newFlow || flowId) && (
          <div className="row p-3">
            {' '}
            <div className="col-5">
              <div>
                <TextField
                  label={t('flowName')}
                  value={flowName}
                  fullWidth
                  onChange={(e) => setFlowName(e.target.value)}
                />
                <TextField
                  label={t('description')}
                  value={description}
                  fullWidth
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="slides">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {slides?.map((slide, index) => (
                        <Draggable
                          key={slide?.id}
                          draggableId={slide?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <SlideComponent
                                slide={slide}
                                slideIndex={index}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {(slides?.[editingSlideIndex]?.id === slideId || newFlow) && (
              <div className="col-7 px-4">
                <div className="d-flex">
                  <ButtonBase
                    onClick={() => handleTemplateChange('A')}
                    sx={{
                      border: '1px solid lightgray',
                      borderRadius: '10px',
                      padding: '10px',
                      minHeight: '50px',
                      margin: '5px',
                      backgroundColor: slideTemplate === 'A' ? 'lightgray' : '',
                    }}
                  >
                    <div>
                      <img
                        src="/assets/v3/vectors/TemplateA.svg"
                        alt="Template A"
                        style={{ width: '100px' }}
                      />
                      <p className="fs-8">Template A</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => handleTemplateChange('B')}
                    sx={{
                      border: '1px solid lightgray',
                      borderRadius: '10px',
                      padding: '10px',
                      minHeight: '50px',
                      margin: '5px',
                      backgroundColor: slideTemplate === 'B' ? 'lightgray' : '',
                    }}
                  >
                    <div>
                      <img
                        src="/assets/v3/vectors/TemplateB.svg"
                        alt="Template B"
                        style={{ width: '100px' }}
                      />
                      <p className="fs-8">Template B</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => handleTemplateChange('C')}
                    sx={{
                      border: '1px solid lightgray',
                      borderRadius: '10px',
                      padding: '10px',
                      margin: '5px',
                      minHeight: '50px',
                      backgroundColor: slideTemplate === 'C' ? 'lightgray' : '',
                    }}
                  >
                    <div>
                      <img
                        src="/assets/v3/vectors/TemplateC.svg"
                        alt="Template C"
                        style={{ width: '100px' }}
                      />
                      <p className="fs-8">Template C</p>
                    </div>
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => handleTemplateChange('D')}
                    sx={{
                      border: '1px solid lightgray',
                      borderRadius: '10px',
                      padding: '10px',
                      margin: '5px',
                      minHeight: '50px',
                      backgroundColor: slideTemplate === 'D' ? 'lightgray' : '',
                    }}
                  >
                    <div>
                      <img
                        src="/assets/v3/vectors/TemplateD.svg"
                        alt="Template D"
                        style={{ width: '100px' }}
                      />
                      <p className="fs-8">Template D</p>
                    </div>
                  </ButtonBase>
                </div>
                <TextField
                  label={t('slideTab')}
                  value={slideTab}
                  fullWidth
                  key={'tab'}
                  onChange={(e) => setSlideTab(e.target.value)}
                />

                <TextField
                  label={t('slideTitle')}
                  value={slideTitle}
                  fullWidth
                  key={'title'}
                  onChange={(e) => setSlideTitle(e.target.value)}
                />
                <TextField
                  label={t('slideContent')}
                  value={slideContent}
                  fullWidth
                  key={'content'}
                  onChange={(e) => setSlideContent(e.target.value)}
                />
                {slideTemplate === 'D' && (
                  <TextField
                    label={t('action')}
                    value={slideSec}
                    fullWidth
                    onChange={(e) => setSlideSec(e.target.value)}
                  />
                )}
                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{
                    border: '1px solid lightgray',
                    borderRadius: '10px',
                    padding: '10px',
                    minHeight: '50px',
                  }}
                >
                  <InputLabel
                    shrink={true}
                    sx={{
                      padding: '2px 10px 2px 10px',
                      borderRadius: '10px',
                    }}
                  >
                    {t('body')}
                  </InputLabel>{' '}
                  <ReactQuill
                    theme="bubble"
                    value={slideBody}
                    onChange={handleSlideBody}
                    modules={{
                      history: {
                        delay: 2000,
                        maxStack: 500,
                        userOnly: true,
                      },
                    }}
                  />
                </FormControl>
                <IconUploader
                  key={'slideMedia'}
                  value={slideMedia}
                  fieldType={'media-single'}
                  required={true}
                  elementId={flowId + 'slideMedia-' + editingSlideIndex}
                  label={'Slide Media'}
                  elementType={'image'}
                  onComplete={handleUploadComplete}
                  onBlur={handleUploadBlur}
                />
                {slideTemplate === 'C' && (
                  <IconUploader
                    key={'slideMediaSec'}
                    fieldType={'media-single'}
                    required={true}
                    elementId={flowId + 'slideMediaSec-' + editingSlideIndex}
                    label={'Slide Media Sec'}
                    elementType={'image'}
                    onComplete={handleUploadCompleteSec}
                    onBlur={handleUploadBlurSec}
                  />
                )}
                <Button
                  buttonSx={{ width: '100%', mt: 4 }}
                  variant="contained"
                  color="primary"
                  label={editingSlideIndex !== null ? t('update') : t('add')}
                  onClick={
                    editingSlideIndex !== null
                      ? handleUpdateSlide
                      : handleAddSlide
                  }
                />
              </div>
            )}
          </div>
        )}
      </Block>
    </MainLayoutV2>
  );
};

export default Marketing;
